.introductionHolder {
  background-color: #ffffff;
  border-radius: 20px;
  padding-top: 50px;
  padding-bottom: 50px;
}

.introduction {
  background: url('../../assets/image/intro-background.png'); 
  text-align: right;
  height: 648px; 
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 20px;
  color: #ffffff; 
  position: relative;
  background-color: #333131;
  background-blend-mode: overlay;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.3);
  background-size: cover;
}


.introduction h2 {
  font-size: 40px;
  margin-bottom: 20px; 
  color: #ff0000; 
  margin-right: 20px;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5); 
  font-family: 'Roboto', sans-serif; 
}

.introduction p {
  font-size: 25px; 
  line-height: 1.75;
  margin-bottom: 15px; 
  color: #250f02;
  text-shadow: 1px 1px 3px rgba(0, 0, 0, 0.5); 
  font-family: 'Open Sans', sans-serif;
  margin-right: 20px;
  
  
}




@media (max-width: 1936px) {
  .introduction h2 {

    top: 0 !important; 
  }

  .introduction p {

    top: 100px !important;
  }    
}


@media (max-width: 1366px) {
  .introduction h2, p {
    margin-top:70px !important;
    margin-right:10px !important;
    right: 0 !important;
    word-break: break-word !important;    
  }

  .introduction h2 {

    top: 0 !important; 
  }

  .introduction p {

    top: 100px !important;
  }  
}

@media (max-width: 1920px) {
  .introduction h2, p {
    border-radius: 15px;
    background-color: white !important;
  }

  .introduction h2 {
    font-size: 54px;

    top: 0; 
  }

  .introduction p {

    top: 70px !important;
  }    
}


@media (max-width: 768px) {
  .introductionHolder {
    padding-top:0;
  }

  .introduction {
    height: auto;
  }

  .introduction h2 {
    font-size: 35px;
    margin-top: 20px !important;
    padding: 8px;
  }  

  .introduction p {
    margin-top: 20px !important;
    font-size: 20px !important; 
    padding: 8px;
  } 
}