.about {
  background: url('../../assets/image/food-craving.gif') no-repeat bottom left;
  text-align: center;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.3);
  padding: 25px 20px;
  overflow: hidden;  
}

.about h2 {
  font-size: 40px; /* Large size for desktop view */
  margin-bottom: 20px;
  color: #000;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
  font-family: 'Roboto', sans-serif;
}

.about p {
  font-size: 20px; /* Appropriate size for desktop view */
  line-height: 1.75;
  color: #250f02;
  text-shadow: 1px 1px 3px rgba(0, 0, 0, 0.5);
  font-family: 'Open Sans', sans-serif;
  width: 50%; /* Fixed width for desktop view */
  margin: 0 auto;
  background-color: #ecf633 !important;
}

/* Media Query for devices with a max-width of 768px (tablets and smaller devices) */
@media (max-width: 768px) {
  .about {
    padding: 15px 10px;

    height: auto;
    background-size: 100px 100px;
    background-position: bottom left;
    
  }

  .about h2 {
    font-size: 28px; 
    text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.5);
    
    
  }

  .about p {
    margin-top: 10px !important;
    margin-bottom: 10px !important;
    font-size: 16px; 
    width: auto; 
    background-color: rgba(236, 243, 51, 0.8); 
  }

  p.aboutBottom {
    width: 70%;
  }
}


