.accordion h2{
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
  font-family: 'Roboto', sans-serif;  
}
.accordion {
  margin: 20px;
  padding: 10px;
  background-color: #f0f0f0; /* Light background, replace with your theme color */
}

.question {
  width: 100%;
  padding: 15px;
  font-size: 16px;
  text-align: left;
  background-color: #004ba0; /* Primary color, adjust accordingly */
  color: white;
  border: none;
  outline: none;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.question:hover {
  background-color: #003580; /* Darker shade for hover effect */
}

.answer {
  padding: 15px;
  background-color: #e1f5fe; /* Lighter shade for the answer, for readability */
  border-left: 5px solid #004ba0; /* Accent border color */
  font-size: 14px;
  line-height: 1.6;
  text-align: left;
  transition: max-height 0.2s ease-out;
}