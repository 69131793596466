.chefs {
  background: url('../../assets/image/chefs.gif') no-repeat right;
  text-align: center;
  height: 412px;
  padding: 25px 20px;

}

.chefs h2 {
  font-size: 40px;
  margin-bottom: 20px;
  color: #000;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
  font-family: 'Roboto', sans-serif;
}

.chefs h3 {
  font-size: 30px;
  margin-bottom: 20px;
  color: #fb6204;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
  font-family: 'Roboto', sans-serif;
}

.chefs p {
  font-size: 20px;
  line-height: 1.75;
  color: #250f02;
  text-shadow: 1px 1px 3px rgba(0, 0, 0, 0.5);
  font-family: 'Open Sans', sans-serif;
  width: 50%;
  margin: 0 auto;
  background-color: #ecf633 !important;
}

@media (max-width: 768px) {
  .chefs {
    height: auto;
    background-size: 100px 100px;
    background-position: bottom right;
  }

  .chefs h2 {
    font-size: 30px;
  }

  .chefs p {
    width: 90%;
    margin-top: 0px !important; 
  }
}


