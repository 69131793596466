.welcomeSection {
  margin-top: 20px;
  width: 80%;
  margin-left: auto;
  margin-right: auto;
}

.welcomeSection h1 {
  font-size: 2.5em;
  margin-bottom: 20px;
  color: #000;
  text-align: center;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
  font-family: 'Roboto', sans-serif;
}

.carousel-control-prev,
.carousel-control-next {
  filter: invert(1);
  width: 5%;
}

.carousel-control-prev-icon,
.carousel-control-next-icon {
  background-color: rgba(0, 0, 0, 0.5);
  border-radius: 50%;
}

.slideContent {
  padding: 20px;
  text-align: left;
  background-color: rgba(255, 255, 255, 0.95);
  border-radius: 10px;
  margin-bottom: 80px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  font-family: 'Arial, sans-serif';
}

.slideContent h3 {
  font-size: 1.8em;
  margin-bottom: 20px;
  color: #ff0000;
  text-align: center;
  text-shadow: 2px 2px 4px rgba(41, 40, 40, 0.5);
}

.slideContent p {
  font-weight: bold;
}

.slideContent p, .slideContent ul {
  font-size: 1em;
  margin-bottom: 20px;
  color: #333;
}

.slideContent ul {
  list-style: none;
  padding-left: 0;
  display: flex;
  flex-wrap: wrap;
}

.slideContent ul li {
  width: 50%;
  box-sizing: border-box;
  margin-bottom: 10px;
  line-height: 20px;
  padding: 5px;
  position: relative;
  padding-left: 35px;
  word-wrap: break-word;
}

.slideContent ul li::before {
  content: "👉";
  position: absolute;
  left: 0;
  top: 0;
  font-size: 1.2em;
}

.carousel-indicators[data-bs-target] {
  height: 20px !important;
}

.carousel-indicators li {
  background-color: #333;
}

.carousel-indicators .active {
  background-color: #007bff;
}

.carousel-caption h3 {
  font-size: 1.5em;
  color: hwb(0 96% 3%);
  margin-bottom: 10px;
}

.carousel-caption button {
  background-color: #007bff;
  border: none;
  color: white;
  padding: 10px 20px;
  font-size: 1em;
  border-radius: 5px;
  transition: background-color 0.3s;
}

.carousel-caption button:hover {
  background-color: #0056b3;
}

.captionHeading {
  font-size: 1.8em;
  color: #ffffff;
  margin-bottom: 15px;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
}

.captionButton {
  background-color: #ff0000;
  border: none;
  color: white;
  padding: 12px 24px;
  font-size: 1em;
  border-radius: 5px;
  transition: background-color 0.3s, transform 0.3s;
  cursor: pointer;
}

.captionButton:hover {
  background-color: #cc0000;
  transform: scale(1.05);
}

/* Media queries for responsiveness */
@media (max-width: 1200px) {
  .welcomeSection {
    width: 90%;
  }

  .welcomeSection h1 {
    font-size: 2.2em;
  }

  .slideContent h3 {
    font-size: 1.6em;
  }

  .slideContent p, .slideContent ul {
    font-size: 0.9em;
  }
}

@media (max-width: 768px) {
  .welcomeSection {
    width: 95%;
  }

  .welcomeSection h1 {
    font-size: 2em;
  }

  .slideContent h3 {
    font-size: 1.4em;
  }

  .slideContent p, .slideContent ul {
    font-size: 0.8em;
  }

  .slideContent ul li {
    width: 100%;
  }
}

