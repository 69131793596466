.container {
  text-align: center;
  position: relative;
  background-color: #ecf633;
}

.floatingButton {
  position: fixed;
  bottom: 20px;
  right: 20px;
  background-color: #ff0000;
  padding: 15px;
  border-radius: 25px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  color: white;
  text-align: center;
  z-index: 1000;
  cursor: pointer;
}

.floatingButton h3 {
  margin: 0;
  font-size: 1.2em;
}

.blinkingHand {
  font-size: 1.5em;
  margin-right: 10px;
  animation: blink 1s infinite;
}

@keyframes blink {
  0%, 100% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
}

@media (max-width: 768px) {
  .floatingButton {
    /* bottom: 20px; */
    bottom: 80px;
  }  
}
