  
.header {
    display: flex;
    align-items: center;
    padding: 10px;
    justify-content: space-between;
    background-color: #000000;
    color: #e0f3ff;
    font-family: 'Poppins', sans-serif;
}

.header.floating {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 1000;
    background-color: #000000;
    box-shadow: 0px 2px 5px rgba(0,0,0,0.2);
    transition: box-shadow 0.3s ease-in-out;
}

.logo {
    max-width: 150px;
    height: 40px;
    flex-shrink: 0;
}

.burger {
    display: none;
    background: none;
    border: none;
    color: #e0f3ff;
    font-size: 1.5em;
    cursor: pointer;
    margin-left: auto;
}

.nav {
    display: flex;
    align-items: center;
    flex-grow: 1; 
    justify-content: center; 
    max-width: calc(80% - 170px);
}

.navList {
    list-style-type: none;
    display: flex;
    margin: 0;
    padding: 0;
    width: 60%; 
    justify-content: space-between; 
}

.nav, .navList {
    display: flex;
    align-items: center; 
    list-style-type: none;
    margin: 0;
    padding: 0;
}

.navItem:hover {
    color: #f8fd40;
}

.navItem {
    margin-right: 20px;
    cursor: pointer;
}

@media (max-width: 360px) {
    .burger {padding-right: 50px;}
    .nav.open {margin-left: 242px !important;}
}

@media (max-width: 576px) {
    .burger {padding-right: 30px;}
    .nav.open {margin-left: 233px;}
}

@media (max-width: 768px) {
    .nav {
        display: none; 
    }

    .burger {
        display: block; 
    }
    .nav.open {
        display: flex; 
        flex-direction: column;
        align-items: flex-start;
        position: absolute;
        top: 50px;
        left: 0;
        right: 0;
        background-color: #000000;
        z-index: 999;
        padding: 10px 0;
    }

    .navList {
        flex-direction: column;
        align-items: flex-start;
        width: 100%;
    }

    .navItem {
        margin: 10px 0;
        width: 100%;
        text-align: left;
        padding: 10px 20px;
    }

    .logo {
        max-width: 56px;
        height: auto;
    }
}
