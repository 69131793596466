.footer-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  background-color: #ecf633;
  background-image: url('../../../assets/image/logo/footer-banner1.png');
  background-size: contain;
  background-repeat: no-repeat;
  background-position: top left;
  color: #000;
  border-top: 1px solid #333; 
  box-shadow: 0 -4px 8px rgba(0, 0, 0, 0.1); 
  margin-top: 20px; 
}

.footer-columns {
  display: flex;
  flex-direction: row;
  justify-content: center;
  flex-wrap: wrap;
  width: 100%;
}



.footer-column {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 10px;
  padding: 10px;
  position: relative;
}

/* Separator for desktop view */
.footer-column:nth-child(2)::after, 
.footer-column:nth-child(3)::after {
  content: '';
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  width: 3px; /* Increased width for separator */
  background: linear-gradient(to bottom, red, orange); /* Gradient color */
  box-shadow: 2px 0 5px rgba(0, 0, 0, 0.1); /* Subtle shadow */
  transition: background 0.3s, width 0.3s, box-shadow 0.3s; /* Smooth transition */
}

.footer-column:nth-child(2):hover::after, 
.footer-column:nth-child(3):hover::after {
  width: 5px; /* Increase width on hover */
  background: linear-gradient(to bottom, orange, red); /* Reverse gradient on hover */
  box-shadow: 3px 0 8px rgba(0, 0, 0, 0.2); /* Enhanced shadow on hover */
}


.logo-footer {
  max-width: 128px;
  width: auto;
  height: auto;
  margin-bottom: 10px;
}

.contact-section {
  color: #000;
  text-align: left;
  width: 100%;
  line-height: 20px;
}

.contact-section h3 {
  color: #ff0000;
  font-weight: bold;
  margin-bottom: 5px;
}

.contact-section .contact-info {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.contact-section .icon {
  margin-right: 10px;
  font-size: 1.2em;
}

.contact-section a {
  color: #000;
  text-decoration: none;
}

.award-image {
  max-width: 320px;
  margin-top: 10px;
  border-radius: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); 
  transition: transform 0.3s; 
}

.award-image:hover {
  transform: scale(1.05);
}

.stay-connected {
  margin-top: 40px;
  font-size: 1.5em;
  font-weight: bold;
  color: #333;
}

.social-icons {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 10px;
}

.social-icon {
  width: 30px;
  height: 30px;
  margin: 0 10px;
  transition: transform 0.3s; 
}

.social-icon:hover {
  transform: scale(1.2); 
}

.logo-grid {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.logo-grid .logo-footer {
  max-width: 30%; 
  margin-bottom: 10px; 
}


@media (max-width: 600px) {
  .footer-column:nth-child(2)::after, 
  .footer-column:nth-child(3)::after {
    top: auto;
    right: auto;
    bottom: 0;
    left: 0;
    height: 3px; /* Adjust height as needed */
    width: 100%;
    border-radius: 0 0 8px 8px; /* Rounded corners for bottom border */
    background: linear-gradient(to right, red, orange); /* Gradient color */
    box-shadow: 0 -2px 5px rgba(0, 0, 0, 0.1); /* Subtle shadow */
  }

  .footer-column:nth-child(2):hover::after, 
  .footer-column:nth-child(3):hover::after {
    height: 5px; /* Increase height on hover */
    background: linear-gradient(to right, orange, red); /* Reverse gradient on hover */
    box-shadow: 0 -3px 8px rgba(0, 0, 0, 0.2); /* Enhanced shadow on hover */
  }
}

@media (max-width: 768px) {
  .logo-footer {
    flex: 1 1 50%; 
    max-width: 50%;
    margin-bottom: 10px;
    padding: 10px;
    
}

.logo-grid .logo {
    width: 100px !important; 
    height: 130px;
}

.logo-grid .ice-cream {
    width: 50px;
    height: 116px; 
}

.logo-grid .herbal-coffee {
    width: 50px; 
    height: 120px;
}

.logo-grid .pares {
    width: 50px; 
    height: 125px;
}



  .footer-columns {
      flex-direction: column;
      align-items: center;
  }

  .footer-column {
      margin: 10px 0;
      width: 100%; 
  }

  .contact-section {
      text-align: center; 
  }

  .contact-section .contact-info {
      justify-content: center; 
  }

  .logo-grid {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    align-items: center;
    flex-direction: row;
  }
  
  .logo-grid .logo-footer {
    flex: 1 1 50%; 
    max-width: 50%; 
    margin-bottom: 10px; 
  }
  
}

.franchise-container {
  display: flex;
  justify-content: center;
  width: 100%;
}

.franchise-image {
  margin-top: 10px;
  padding: 0;
  width: auto;
  height: auto;
}

@media (max-width: 1536px) {  
  .franchise-image {
    max-height: 242px; 
    width: auto;
    height: auto;

    margin-top: 20px;
    padding: 0;    
  }
}

@media (max-width: 1366px) {  
  .franchise-image {
    max-height: 290px; 
    width: auto;
    height: auto;
  }
}

@media (max-width: 768px) {
  .footer-section {
    background-image: none;
  }

  .franchise-container img {
    margin-top: 10px !important;
    width: 100%;
    
  }
  .footer-column {
    padding-top: 0px !important;
  }
}

.crafted-by {
  margin-top: 40px;
  font-size: 1.1em;
  color: #333;
  text-align: center;
  padding: 15px;
  background-color: #fff; 
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: background-color 0.3s, box-shadow 0.3s;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif; 
}

.crafted-by:hover {
  background-color: #f0f0f0; 
  box-shadow: 0 6px 8px rgba(0, 0, 0, 0.15);
}

.crafted-by a {
  color: #0073e6;
  font-weight: bold;
  text-decoration: none;
  transition: color 0.3s;
  background: linear-gradient(45deg, #fe0000, #ffaa01);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.crafted-by a:hover {
  text-decoration: underline;
}

.our-brand {
  font-size: 1.5em;
  font-weight: bold;
  margin-bottom: 10px;
  color: #333;
  text-align: center;
}

