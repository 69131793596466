/* Base styles */
.products {
  padding: 4vw; /* Responsive padding */
  text-align: center;
  background-color: #f9f9f9;
}

.products h2 {
  font-size: 3vw; /* Responsive font size */
  margin-bottom: 1em;
  color: #000;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
  font-family: 'Roboto', sans-serif;
}

.media {
  display: flex;
  justify-content: center;
  gap: 20px;
  padding: 20px;
  flex-wrap: wrap;
}

.video {
  width: 100%;
  aspect-ratio: 16 / 9; /* Maintains aspect ratio */
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease-in-out; /* Smooth transform */
}

.video:hover {
  transform: scale(1.05); /* Visual feedback */
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .products {
    padding-top: 0;
  }

  .products h2 {
    font-size: 25px;
    padding-top: 10px;
  }

  .media {
    flex-direction: column;
  }

  .video {
    max-width: 100%; /* Full width on smaller screens */
  }
}

@media (min-width: 769px) and (max-width: 1200px) {
  .video {
    max-width: 75%; /* Larger video on medium screens */
  }
}

@media (min-width: 1201px) {
  .video {
    max-width: 75%; /* Larger video on large screens */
  }
}



/* .products {
  padding: 40px;
  text-align: center;
  background-color: #f9f9f9;
}

.products h2 {
  font-size: 2.5em;
  margin-bottom: 20px;
  color: #000;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
  font-family: 'Roboto', sans-serif;
}

.media {
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 20px;
  padding: 20px;
  flex-wrap: wrap;
}

.video {
  width: 100%;
  max-width: 560px;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}


@media (max-width: 768px) {
  .products {
    padding: 20px;
  }

  .products h2 {
    font-size: 2em;
  }

  .media {
    flex-direction: column;
    gap: 10px;
  }

  .video {
    max-width: 100%;
    height: auto; 
  }
}


@media (min-width: 769px) and (max-width: 1200px) {
  .products {
    padding: 30px;
  }

  .products h2 {
    font-size: 2.2em;
  }

  .media {
    flex-direction: row;
    gap: 15px;
  }

  .video {
    max-width: 48%;
  }
}


@media (min-width: 1201px) {
  .products {
    padding: 40px;
  }

  .products h2 {
    font-size: 2.5em;
  }

  .media {
    flex-direction: row;
    gap: 20px;
  }

  .video {
    max-width: 33%;
  }
}
 */
